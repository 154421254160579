// extracted by mini-css-extract-plugin
export var animLine = "Community-module--animLine--glqIJ";
export var community = "Community-module--community--t3Z4X";
export var container = "Community-module--container--G1tTS";
export var description = "Community-module--description--+P8sf";
export var fadeDown = "Community-module--fadeDown--URKn3";
export var fadeUp = "Community-module--fadeUp--1VwMR";
export var flowing = "Community-module--flowing--p4r9a";
export var head = "Community-module--head--IVZ0a";
export var icon = "Community-module--icon--YwWAF";
export var image = "Community-module--image--lGci-";
export var imageWrap = "Community-module--imageWrap--YF8kF";
export var lineIn = "Community-module--lineIn--2i2SA";
export var time = "Community-module--time--AD1iB";
export var title = "Community-module--title--QVtN4";