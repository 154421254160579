import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'

import MediaBreak from '@/components/Parts/MediaBreak'
import OvalLeft from '@/components/Path/OvalLeft'
import OvalRight from '@/components/Path/OvalRight'
import * as styles from '@/components/Top/About/About.module.scss'
import { useIntersection } from '@/hooks/useIntersection'

const TopAbout = () => {
  const fukuiRef = React.useRef<HTMLDivElement>(null)
  const viewFukui = useIntersection({ target: fukuiRef, margin: '-40% 0%' })

  const ovalImgWrapRef = React.useRef<HTMLDivElement>(null)
  const viewOvalImgWrap = useIntersection({
    target: ovalImgWrapRef,
    margin: '-40% 0%',
  })

  return (
    <section className={styles['about']}>
      <div className={styles['japanWrap']}>
        <StaticImage
          className={styles['japan']}
          src='../../../images/top/japan.png'
          alt='Japan'
          placeholder='tracedSVG'
        />
      </div>
      <div
        className={`${styles['fukui']} ${
          viewFukui ? styles['animFukui'] : undefined
        }`}
        ref={fukuiRef}
      >
        <span>
          FUKUI
          <br />
          SABAE
        </span>
      </div>
      <h1 className={styles['title']}>
        鯖江の
        <MediaBreak media='mobile' />
        コミュニティをつくる
        <br />
        カフェ&ホステル
      </h1>
      <p className={styles['description']}>
        La
        Tempoは2022年9月に福井県鯖江市のまちなかにオープンした、カフェ＆ホステルを併設したコミュニティスペースです。商店街にある空きビルをリノベーションして、おしゃれな「集うビル」に変化させました。鯖江の人も市外の人も、気楽に食べたり飲んだり、たまにはお仕事してみたり。そしてゆっくり鯖江を楽しむ時は泊まってみてください。
      </p>
      <div className={styles['ovalImgWrap']} ref={ovalImgWrapRef}>
        <OvalLeft />
        <div
          className={`${styles['ovalLeftImgWrap']} ${
            viewOvalImgWrap ? styles['animOvalLeft'] : undefined
          }`}
        >
          <StaticImage
            className={styles['ovalLeftImg']}
            src='../../../images/top/sabae1.png'
            alt='Sabae1'
            placeholder='blurred'
          />
        </div>
        <OvalRight />
        <div
          className={`${styles['ovalRightImgWrap']} ${
            viewOvalImgWrap ? styles['animOvalRight'] : undefined
          }`}
        >
          <StaticImage
            className={styles['ovalRightImg']}
            src='../../../images/top/sabae2.png'
            alt='Sabae2'
            placeholder='blurred'
          />
        </div>
      </div>
      <div className={styles['sabaeWrap']}>
        <h2 className={styles['sabae']}>鯖江というまちについて</h2>
        <p className={styles['aboutSabae']}>
          まちの中心地にある本山
          誠照寺の門前町として発展してきた寺町鯖江は、めがねのまちとしても有名です。
          めがね以外にも、漆器、繊維という地場産業がある”ものづくのまち”で、日本海側最大のつつじの名所
          西山公園には無料の動物園があり、レッサーパンダやリスザル達がお出迎えしてくれます。
        </p>
      </div>
      <Link className={styles['link']} to='/about'>
        <span>もっとみる</span>
      </Link>
    </section>
  )
}

export default TopAbout
