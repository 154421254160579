import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'

import Button from '@/components/Parts/Button'
import MediaBreak from '@/components/Parts/MediaBreak'
import * as styles from '@/components/Top/Community/Community.module.scss'
import { useIntersection } from '@/hooks/useIntersection'
import communityIcon from '@/images/icons/community.svg'

const TopCommunity = () => {
  const titleRef = React.useRef<HTMLHeadingElement>(null)
  const viewTitle = useIntersection({ target: titleRef, margin: '-40% 0%' })

  return (
    <div className={styles['community']} id='community'>
      <div className={styles['container']}>
        <div className={styles['head']}>
          <h2
            className={`${styles['title']} ${
              viewTitle ? styles['animLine'] : undefined
            }`}
            ref={titleRef}
          >
            コミュニティスペース
          </h2>
          <img
            className={styles['icon']}
            src={communityIcon}
            alt='communityIcon'
          />
        </div>
        <p className={styles['description']}>
          ワークショップやイベントを開催してみませんか？
          <MediaBreak media='desktop' />
          たくさんの人がここに集い、そして交流する。
          <MediaBreak media='desktop' />
          そんな場としてお使いいただけます。
          <MediaBreak media='desktop' />
          ホワイトボード、大きなモニターもありますので
          <MediaBreak media='desktop' />
          企業研修や学生さんの研修としてもおすすめです。
        </p>
        <p className={styles['time']}>営業時間　11:00-17:00</p>
        <Button text='もっとみる' href='/community' />
      </div>
      <div className={styles['imageWrap']}>
        <StaticImage
          className={styles['image']}
          src='../../../images/top/community.png'
          alt='Community'
          placeholder='blurred'
        />
      </div>
    </div>
  )
}

export default TopCommunity
