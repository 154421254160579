// extracted by mini-css-extract-plugin
export var exhibitor = "Exhibitor-module--exhibitor--1r-7e";
export var fadeDown = "Exhibitor-module--fadeDown--txeq+";
export var fadeUp = "Exhibitor-module--fadeUp--fTDNX";
export var flowing = "Exhibitor-module--flowing--jPqiL";
export var icon = "Exhibitor-module--icon--CUxBg";
export var iconWrap = "Exhibitor-module--iconWrap--huoXp";
export var image = "Exhibitor-module--image--xHD46";
export var instagram = "Exhibitor-module--instagram--rhi4M";
export var lineIn = "Exhibitor-module--lineIn--zgUqR";
export var name = "Exhibitor-module--name--sqC0u";
export var web = "Exhibitor-module--web--ja5Wa";