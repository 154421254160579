// extracted by mini-css-extract-plugin
export var about = "About-module--about--DaXHs";
export var aboutSabae = "About-module--aboutSabae--5IIxD";
export var animFukui = "About-module--animFukui--oY3OQ";
export var animOvalLeft = "About-module--animOvalLeft--9cQIz";
export var animOvalRight = "About-module--animOvalRight--gvjo7";
export var description = "About-module--description--B6pyh";
export var fadeDown = "About-module--fadeDown--amsDU";
export var fadeUp = "About-module--fadeUp--Iozad";
export var flowing = "About-module--flowing--rnvkn";
export var fukui = "About-module--fukui--kM7Hl";
export var japan = "About-module--japan--Nvjb7";
export var japanWrap = "About-module--japanWrap--R4tK9";
export var lineIn = "About-module--lineIn--3owwN";
export var link = "About-module--link--6mVYK";
export var ovalImgWrap = "About-module--ovalImgWrap--2nbca";
export var ovalLeftImg = "About-module--ovalLeftImg--6oSsM";
export var ovalLeftImgWrap = "About-module--ovalLeftImgWrap--nFqTv";
export var ovalRightImg = "About-module--ovalRightImg--8m1o5";
export var ovalRightImgWrap = "About-module--ovalRightImgWrap--hrapK";
export var sabae = "About-module--sabae--AJbBo";
export var sabaeWrap = "About-module--sabaeWrap--V8-0X";
export var title = "About-module--title--P4Bmg";