import * as React from 'react'

import MediaBreak from '@/components/Parts/MediaBreak'
import * as styles from '@/components/Top/Access/Access.module.scss'
import carIcon from '@/images/icons/car.svg'
import mailIcon from '@/images/icons/mail.svg'
import phoneIcon from '@/images/icons/phone.svg'
import trainIcon from '@/images/icons/train.svg'

const TopAccess = () => {
  return (
    <section className={styles['access']}>
      <div className={styles['info']}>
        <div className={styles['head']}>
          <h1 className={styles['title']}>アクセス</h1>
          <span className={styles['sub']}>Access</span>
        </div>
        <address className={styles['address']}>
          <p>
            〒916-0026　
            <MediaBreak media='mobile' />
            福井県鯖江市本町1-3-19
          </p>
          <div className={styles['anchor']}>
            <div className={styles['iconWrap']}>
              <a className={styles['link']} href='mailto:latempo2022@gmail.com'>
                {null}
              </a>
              <img className={styles['icon']} src={mailIcon} alt='mail' />
              <p>latempo2022@gmail.com</p>
            </div>
            <div className={styles['iconWrap']}>
              <a className={styles['link']} href='tel:0778-42-8839'>
                {null}
              </a>
              <img className={styles['icon']} src={phoneIcon} alt='phone' />
              <p>0778-42-8839</p>
            </div>
            <div className={styles['note']}>
              ※電話での対応はLa Tempoカフェ営業中
              <MediaBreak media='mobile' />
              <span className={styles['mobileOnly']}>　</span>のみとなります。
              <br />
              ※ホステルに関するお問い合わせは、メー
              <MediaBreak media='mobile' />
              <span className={styles['mobileOnly']}>　</span>
              ルのみでの対応となります。
            </div>
          </div>
        </address>
      </div>
      <div className={styles['mapWrap']}>
        <iframe
          className={styles['map']}
          title='LaTempo Map'
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3230.0781711820873!2d136.1829100505668!3d35.945057622968044!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5ff8afef4a7901df%3A0xe17518f6a5f6efb6!2sLa%20Tempo!5e0!3m2!1sja!2sjp!4v1673434040853!5m2!1sja!2sjp'
          allowFullScreen={true}
          referrerPolicy='no-referrer-when-downgrade'
          loading='lazy'
        />
      </div>
      <a
        className={styles['button']}
        href='https://goo.gl/maps/5G6M28YYEXYo7Eaw5'
        target='_blank'
        rel='noopener noreferrer'
      >
        <span>Google mapでみる</span>
      </a>
      <div className={styles['route']}>
        <div className={styles['car']}>
          <div className={styles['carIconWrap']}>
            <img className={styles['carIcon']} src={carIcon} alt='car' />
            <p>車でお越しの方</p>
          </div>
          <p className={styles['description']}>
            北陸自動車道　鯖江ICより10分
            <br />
            <br />
            駐車場2台(宿泊者優先)
            <br />
            ※満車時は付近の有料駐車場に
            <br />
            　停車いただきますようお願いいたします。
          </p>
        </div>
        <div>
          <div className={styles['trainIconWrap']}>
            <img className={styles['trainIcon']} src={trainIcon} alt='train' />
            <p>電車でお越しの方</p>
          </div>
          <p className={styles['description']}>
            <b>東京方面から</b>
            <br />
            北陸新幹線　
            <MediaBreak media='mobile' />
            金沢駅下車→特急サンダーバード鯖江駅下車
            <br />
            東海道新幹線　
            <MediaBreak media='mobile' />
            米原駅下車→特急しらさぎ　鯖江駅下車
            <br />
            <br />
            <b>大阪方面から</b>
            <br />
            特急サンダーバード　鯖江駅下車
          </p>
        </div>
      </div>
    </section>
  )
}

export default TopAccess
