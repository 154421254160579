// extracted by mini-css-extract-plugin
export var abilities = "Ability-module--abilities--GSSC6";
export var ability = "Ability-module--ability--kkBRf";
export var arrow = "Ability-module--arrow--tB+JI";
export var cafe = "Ability-module--cafe--4P+Hb";
export var community = "Ability-module--community--S8s8s";
export var description = "Ability-module--description--kheK7";
export var fadeDown = "Ability-module--fadeDown--TKLLI";
export var fadeUp = "Ability-module--fadeUp--Xfi0d";
export var flowing = "Ability-module--flowing--kwmnc";
export var hostel = "Ability-module--hostel--gf29B";
export var item = "Ability-module--item--8M4Py";
export var itemSub = "Ability-module--itemSub--Iwlm7";
export var itemSubMobile = "Ability-module--itemSubMobile--izj8x";
export var itemTitle = "Ability-module--itemTitle--Jc+hP";
export var lineIn = "Ability-module--lineIn--vNpEX";
export var link = "Ability-module--link--IQGw2";
export var paper = "Ability-module--paper--Bbug1";
export var title = "Ability-module--title---iSQ1";