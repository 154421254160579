// extracted by mini-css-extract-plugin
export var animLine = "Hostel-module--animLine--G8XXO";
export var buttonWrap = "Hostel-module--buttonWrap--mrKzr";
export var description = "Hostel-module--description--TQ3vD";
export var fadeDown = "Hostel-module--fadeDown--PTNKD";
export var fadeUp = "Hostel-module--fadeUp--kS3SL";
export var flowing = "Hostel-module--flowing--0KYeC";
export var head = "Hostel-module--head--DEa8w";
export var hostel = "Hostel-module--hostel--9-YQV";
export var icon = "Hostel-module--icon--oX98C";
export var image = "Hostel-module--image--zWu+I";
export var lineIn = "Hostel-module--lineIn--iB5ma";
export var title = "Hostel-module--title--YNYb6";