import { Link } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import * as React from 'react'

import * as styles from '@/components/Parts/Article/Article.module.scss'

type Props = {
  id: string
  title: string
  body: string
  date: string
  image: IGatsbyImageData
}

const Article = ({ id, title, body, date, image }: Props) => {
  return (
    <article className={styles['article']}>
      <GatsbyImage className={styles['image']} image={image} alt={title} />
      <div className={styles['container']}>
        <h2 className={styles['title']}>{title}</h2>
        <time className={styles['time']}>
          {new Date(date).toLocaleDateString('ja-JP').replaceAll('/', ' / ')}
        </time>
        <p className={styles['body']}>{body.replace(/(<([^>]+)>)/gi, '')}</p>
      </div>
      <Link className={styles['link']} to={`/news/${id}`} />
    </article>
  )
}

export default Article
