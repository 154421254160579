import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import * as React from 'react'
import { Autoplay, EffectFade, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/scss'
import 'swiper/scss/effect-fade'
import 'swiper/scss/pagination'
import '@/components/Top/Main/swiper.scss'
import * as styles from '@/components/Top/Main/Main.module.scss'
import { useIntersection } from '@/hooks/useIntersection'
import { useSlideImages } from '@/hooks/useSlideImages'
import LogoSvg from '@/images/logo/logo_white.svg'
import Tagline from '@/images/logo/tagline.svg'

const TopMain = () => {
  const titleRef = React.useRef<HTMLDivElement>(null)
  const viewTitle = useIntersection({ target: titleRef })
  const slideImageList = useSlideImages()

  return (
    <section className={styles['main']}>
      <div className={styles['container']}>
        <div className={styles['logo']}>
          <img src={LogoSvg} alt='Logo' />
          <Link className={styles['link']} to='/' />
        </div>
        <div className={styles['title']} ref={titleRef}>
          <div
            className={`${styles['titleImgWrap']} ${
              viewTitle ? styles['animTitle'] : undefined
            }`}
          >
            <img className={styles['titleImg']} src={Tagline} alt='Title' />
          </div>
        </div>
        <Swiper
          className='slider-main'
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          speed={1000}
          effect={'fade'}
          pagination={{
            clickable: true,
          }}
          loop={true}
          loopedSlides={4}
          modules={[Autoplay, EffectFade, Pagination]}
        >
          {slideImageList
            ? Object.values(slideImageList).map((slideImage, index) => {
                const image = getImage(slideImage.gatsbyImageData)
                return image ? (
                  <SwiperSlide key={index}>
                    <GatsbyImage
                      className={styles['slideImg']}
                      image={image}
                      alt={`Slide-${index}`}
                    />
                  </SwiperSlide>
                ) : undefined
              })
            : undefined}
        </Swiper>
      </div>
    </section>
  )
}

export default TopMain
