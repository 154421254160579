import { getImage } from 'gatsby-plugin-image'
import * as React from 'react'

import Button from '@/components/Parts/Button'
import Exhibitor from '@/components/Parts/Exhibitor'
import MediaBreak from '@/components/Parts/MediaBreak'
import * as styles from '@/components/Top/Exhibitors/Exhibitors.module.scss'
import { useExhibitors } from '@/hooks/useExhibitors'

const TopExhibitors = () => {
  const exhibitorList = useExhibitors()

  const [incrementalValue, setIncrementalValue] = React.useState<number>(4)
  const [limit, setLimit] = React.useState<number>(incrementalValue)

  React.useEffect(() => {
    setIncrementalValue(window.innerWidth < 768 ? 4 : 8)
    setLimit(incrementalValue)
  }, [incrementalValue])

  const incrementLimit = React.useCallback(
    () => setLimit(limit + incrementalValue),
    [limit, incrementalValue]
  )

  return exhibitorList.length ? (
    <div className={styles['exhibitors']}>
      <div className={styles['head']}>
        <h2 className={styles['title']}>
          <span>過去の出店者リスト</span>
        </h2>
        <p className={styles['info']}>
          出店をご希望の方は、
          <MediaBreak media='desktop' />
          ページ下部に記載のメールよりご連絡ください。
        </p>
      </div>
      <div className={styles['list']}>
        {exhibitorList.slice(0, limit).map((exhibitor) => {
          const image = getImage(exhibitor.imgixImage.gatsbyImageData)
          return image ? (
            <Exhibitor
              key={exhibitor.exhibitorsId}
              name={exhibitor.name}
              image={image}
              web={exhibitor.web}
              instagram={exhibitor.instagram}
            />
          ) : undefined
        })}
      </div>
      <Button
        text='もっとみる'
        onClick={incrementLimit}
        disabled={exhibitorList.length <= limit}
      />
    </div>
  ) : (
    <></>
  )
}

export default TopExhibitors
