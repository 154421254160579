import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'

import Button from '@/components/Parts/Button'
import * as styles from '@/components/Top/Cafe/Cafe.module.scss'
import { useIntersection } from '@/hooks/useIntersection'
import cafeIcon from '@/images/icons/cafe.svg'

const TopCafe = () => {
  const titleRef = React.useRef<HTMLHeadingElement>(null)
  const viewTitle = useIntersection({ target: titleRef, margin: '-40% 0%' })

  return (
    <div className={styles['cafe']} id='cafe'>
      <div className={styles['container']}>
        <div className={styles['head']}>
          <h2
            className={`${styles['title']} ${
              viewTitle ? styles['animLine'] : undefined
            }`}
            ref={titleRef}
          >
            カフェスペース
          </h2>
          <img className={styles['icon']} src={cafeIcon} alt='cafeIcon' />
        </div>
        <p className={styles['description']}>
          いろんな人のいろんな料理、デザートが楽しめます。その日何を楽しめるかはカレンダーを見てのお楽しみ。シェアキッチン利用者も募集中です。
          <br />
          週に数日や月に1度だけお店をしてみませんか？
        </p>
        <p className={styles['time']}>営業時間　11:00-17:00（L.O. 16:30）</p>
        <Button text='もっとみる' href='/kitchen' />
      </div>
      <div className={styles['imageWrap']}>
        <StaticImage
          className={styles['image']}
          src='../../../images/top/cafe.png'
          alt='Cafe'
          placeholder='blurred'
        />
      </div>
    </div>
  )
}

export default TopCafe
