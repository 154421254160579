import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import * as React from 'react'
import { Autoplay, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/scss'
import 'swiper/scss/navigation'
import '@/components/Top/Hostel/swiper.scss'
import Button from '@/components/Parts/Button'
import Parallelogram from '@/components/Path/Parallelogram'
import * as styles from '@/components/Top/Hostel/Hostel.module.scss'
import { useHostelImages } from '@/hooks/useHostelImages'
import { useIntersection } from '@/hooks/useIntersection'
import hostelIcon from '@/images/icons/hostel.svg'

const TopHostel = () => {
  const titleRef = React.useRef<HTMLHeadingElement>(null)
  const viewTitle = useIntersection({ target: titleRef, margin: '-40% 0%' })
  const hostelImageList = useHostelImages()

  return (
    <div className={styles['hostel']} id='hostel'>
      <div className={styles['head']}>
        <img className={styles['icon']} src={hostelIcon} alt='hostelIcon' />
        <h2
          className={`${styles['title']} ${
            viewTitle ? styles['animLine'] : undefined
          }`}
          ref={titleRef}
        >
          ホステルに泊まる
        </h2>
      </div>
      <p className={styles['description']}>
        個室が4部屋。どのお部屋もこの地域をちょっとだけ感じられる個性的なお部屋です。
        <br />
        ビルの中とは思えない木のぬくもりを感じながら、ゆっくりお休みいただけます。
      </p>
      <Parallelogram />
      {hostelImageList ? (
        <Swiper
          className='slider-hostel'
          grabCursor={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          speed={1000}
          loop={true}
          loopedSlides={hostelImageList.length}
          navigation={true}
          slidesPerView={'auto'}
          centeredSlides={true}
          modules={[Autoplay, Navigation]}
        >
          {Object.values(hostelImageList).map((hostelImage, index) => {
            const image = getImage(hostelImage.imgixImage.gatsbyImageData)
            return image ? (
              <SwiperSlide key={hostelImage.hostelImagesId}>
                <GatsbyImage
                  className={styles['image']}
                  image={image}
                  alt={`Hostel-${index}`}
                />
              </SwiperSlide>
            ) : undefined
          })}
        </Swiper>
      ) : undefined}
      <div className={styles['buttonWrap']}>
        <Button text='もっとみる' href='/hostel' />
        <Button
          text='ご予約はこちら'
          href='https://tabichat.jp/engine/hotels/latempo_sabae?adults=1'
        />
      </div>
    </div>
  )
}

export default TopHostel
