import * as React from 'react'

const OvalRight = () => {
  const width = 229
  const height = 264
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='0'
      height='0'
      viewBox={`0 0 ${width} ${height}`}
    >
      <clipPath id='ovalRight' clipPathUnits='objectBoundingBox'>
        <path
          transform={`scale(${1 / width}, ${1 / height})`}
          d='M33.08,213.32C10.94,185.49-.08,153.25,0,116.61,1.08,80.34,12.87,50.97,35.34,28.5,57.51,6.33,84.67-2.91,116.86,.79c31.56,4.33,58.38,20.4,80.51,48.22,21.11,27.43,31.64,59.81,31.6,97.14-.06,36.65-11.34,66.2-33.82,88.69-22.79,22.79-50.11,32.18-81.98,28.19-30.89-4.3-57.58-20.88-80.09-49.72Z'
        />
      </clipPath>
    </svg>
  )
}

export default OvalRight
