import { useStaticQuery, graphql } from 'gatsby'

export const useHostelImages = () => {
  const { allMicrocmsHostelImages } = useStaticQuery<Queries.HostelImagesQuery>(
    graphql`
      query HostelImages {
        allMicrocmsHostelImages(sort: { fields: sortIndex, order: ASC }) {
          nodes {
            sortIndex
            hostelImagesId
            imgixImage {
              gatsbyImageData
            }
          }
        }
      }
    `
  )

  return allMicrocmsHostelImages.nodes
}
