import { getImage } from 'gatsby-plugin-image'
import * as React from 'react'

import Article from '@/components/Parts/Article'
import Button from '@/components/Parts/Button'
import * as styles from '@/components/Top/News/News.module.scss'
import { useNews } from '@/hooks/useNews'

const TopNews = () => {
  const newsList = useNews()

  return newsList.length ? (
    <section className={styles['news']}>
      <div className={styles['container']}>
        <h1 className={styles['title']}>お知らせ</h1>
        <span className={styles['sub']}>News / Event</span>
        <div className={styles['article']}>
          {newsList.slice(0, 3).map((news) => {
            const image = getImage(news.imgixImage.gatsbyImageData)
            return image ? (
              <Article
                key={news.newsId}
                id={news.newsId}
                title={news.title}
                body={news.body}
                date={news.publishedAt}
                image={image}
              />
            ) : undefined
          })}
        </div>
      </div>
      <Button text='もっとみる' href='/news' />
    </section>
  ) : (
    <></>
  )
}

export default TopNews
