import scrollTo from 'gatsby-plugin-smoothscroll'
import * as React from 'react'

import MediaBreak from '@/components/Parts/MediaBreak'
import * as styles from '@/components/Top/Ability/Ability.module.scss'
import TopCafe from '@/components/Top/Cafe'
import TopCommunity from '@/components/Top/Community'
import TopExhibitors from '@/components/Top/Exhibitors'
import TopHostel from '@/components/Top/Hostel'
import cafeIcon from '@/images/icons/cafe.svg'
import communityIcon from '@/images/icons/community.svg'
import hostelIcon from '@/images/icons/hostel.svg'

const TopAbility = () => {
  return (
    <section className={styles['ability']}>
      <div className={styles['paper']}>
        <h1 className={styles['title']}>
          La Tempo で
          <MediaBreak media='mobile' />
          できること
        </h1>
        <p className={styles['description']}>
          ホステルとして宿泊することも、カフェとしてゆっくり飲食を楽しんでいただくこともできます。コミュニティスペースとしていろんな方にいろんな使い方をしていただけるようシェアキッチンを備えています。
        </p>
        <div className={styles['abilities']}>
          <div className={styles['item']} onClick={() => scrollTo('#cafe')}>
            <span className={styles['itemTitle']}>Cafe</span>
            <span className={`${styles['itemSub']} ${styles['itemSubMobile']}`}>
              カフェスペース
            </span>
            <img className={styles['cafe']} src={cafeIcon} alt='cafeIcon' />
            <div className={styles['arrow']} />
            <button
              className={styles['link']}
              onClick={() => scrollTo('#cafe')}
            />
          </div>
          <div className={styles['item']} onClick={() => scrollTo('#hostel')}>
            <span className={styles['itemTitle']}>Hostel</span>
            <span className={`${styles['itemSub']} ${styles['itemSubMobile']}`}>
              ホステル
            </span>
            <img
              className={styles['hostel']}
              src={hostelIcon}
              alt='hostelIcon'
            />
            <div className={styles['arrow']} />
            <button
              className={styles['link']}
              onClick={() => scrollTo('#hostel')}
            />
          </div>
          <div
            className={styles['item']}
            onClick={() => scrollTo('#community')}
          >
            <span className={styles['itemTitle']}>
              Community <MediaBreak media='mobile' />
              space
            </span>
            <span className={styles['itemSub']}>
              コミュニティ
              <MediaBreak media='mobile' />
              スペース
            </span>
            <img
              className={styles['community']}
              src={communityIcon}
              alt='communityIcon'
            />
            <div className={styles['arrow']} />
            <button
              className={styles['link']}
              onClick={() => scrollTo('#community')}
            />
          </div>
        </div>
        <TopCafe />
        <TopExhibitors />
        <TopHostel />
        <TopCommunity />
      </div>
    </section>
  )
}

export default TopAbility
