// extracted by mini-css-extract-plugin
export var animTitle = "Main-module--animTitle--ZbchK";
export var container = "Main-module--container--V-8Vb";
export var fadeDown = "Main-module--fadeDown--qhFIp";
export var fadeUp = "Main-module--fadeUp--ymIM2";
export var flowing = "Main-module--flowing--Qtqky";
export var lineIn = "Main-module--lineIn--g17A+";
export var link = "Main-module--link--dw3+V";
export var logo = "Main-module--logo--Yk+KM";
export var main = "Main-module--main--bfi7m";
export var slideImg = "Main-module--slideImg--cgHhz";
export var title = "Main-module--title--Q0VDE";
export var titleImg = "Main-module--titleImg--XnLkb";
export var titleImgWrap = "Main-module--titleImgWrap--Tlqb9";