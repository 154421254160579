import { useStaticQuery, graphql } from 'gatsby'

export const useSlideImages = () => {
  const { microcmsSlideImages } = useStaticQuery<Queries.SlideImagesQuery>(
    graphql`
      query SlideImages {
        microcmsSlideImages {
          imgixSlide1 {
            gatsbyImageData(placeholder: BLURRED)
          }
          imgixSlide2 {
            gatsbyImageData(placeholder: NONE)
          }
          imgixSlide3 {
            gatsbyImageData(placeholder: NONE)
          }
          imgixSlide4 {
            gatsbyImageData(placeholder: NONE)
          }
        }
      }
    `
  )
  return microcmsSlideImages
}
