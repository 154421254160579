import { Link } from 'gatsby'
import * as React from 'react'

import * as styles from '@/components/Parts/Button/Button.module.scss'

type Props = {
  text: string
  disabled?: boolean
  href?: string
  onClick?: () => void
}

const Button = ({ text, disabled, href, onClick }: Props) => {
  return href ? (
    href.includes('http') ? (
      <a
        className={styles['link']}
        href={href}
        target='_blank'
        rel='noopener noreferrer'
      >
        <span>{text}</span>
      </a>
    ) : (
      <Link className={styles['link']} to={href}>
        <span>{text}</span>
      </Link>
    )
  ) : (
    <button className={styles['link']} disabled={disabled} onClick={onClick}>
      <span>{text}</span>
    </button>
  )
}

export default Button
