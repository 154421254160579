import { useStaticQuery, graphql } from 'gatsby'

export const useExhibitors = () => {
  const { allMicrocmsExhibitors } = useStaticQuery<Queries.ExhibitorsQuery>(
    graphql`
      query Exhibitors {
        allMicrocmsExhibitors(sort: { fields: sortIndex, order: ASC }) {
          nodes {
            sortIndex
            exhibitorsId
            name
            web
            instagram
            imgixImage {
              gatsbyImageData
            }
          }
        }
      }
    `
  )

  return allMicrocmsExhibitors.nodes
}
