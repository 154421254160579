import { GatsbyImage } from 'gatsby-plugin-image'
import * as React from 'react'

import type { IGatsbyImageData } from 'gatsby-plugin-image'

import * as styles from '@/components/Parts/Exhibitor/Exhibitor.module.scss'
import webIcon from '@/images/icons/web.svg'
import instagramIcon from '@/images/sns/instagram_black.svg'

type Props = {
  name: string
  image: IGatsbyImageData
  web?: string | null
  instagram?: string | null
}

const Exhibitor = ({ name, image, web, instagram }: Props) => {
  return (
    <div className={styles['exhibitor']}>
      <div>
        <GatsbyImage className={styles['image']} image={image} alt={name} />
      </div>
      <span className={styles['name']}>{name}</span>
      <div className={styles['iconWrap']}>
        {web ? (
          <a
            className={styles['icon']}
            href={web}
            target='_blank'
            rel='noopener noreferrer'
          >
            <img className={styles['web']} src={webIcon} alt='web' />
          </a>
        ) : undefined}
        {instagram ? (
          <a
            className={styles['icon']}
            href={instagram}
            target='_blank'
            rel='noopener noreferrer'
          >
            <img
              className={styles['instagram']}
              src={instagramIcon}
              alt='instagram'
            />
          </a>
        ) : undefined}
      </div>
    </div>
  )
}

export default Exhibitor
