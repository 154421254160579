import * as React from 'react'

import type { HeadFC } from 'gatsby'

import SEO from '@/components/Base/SEO'
import Layout from '@/components/Layout'
import TopAbility from '@/components/Top/Ability'
import TopAbout from '@/components/Top/About'
import TopAccess from '@/components/Top/Access'
import TopMain from '@/components/Top/Main'
import TopNews from '@/components/Top/News'

const IndexPage = () => {
  return (
    <Layout logo={false}>
      <main>
        <TopMain />
        <TopAbout />
        <TopNews />
        <TopAbility />
        <TopAccess />
      </main>
    </Layout>
  )
}

export default IndexPage

export const Head: HeadFC = () => <SEO />
