// extracted by mini-css-extract-plugin
export var article = "Article-module--article--j8JnS";
export var body = "Article-module--body--HpJfP";
export var container = "Article-module--container--PDaXH";
export var fadeDown = "Article-module--fadeDown--oFHhr";
export var fadeUp = "Article-module--fadeUp--3movj";
export var flowing = "Article-module--flowing--PYfHd";
export var image = "Article-module--image--RSCxM";
export var lineIn = "Article-module--lineIn--LqPFR";
export var link = "Article-module--link--KyE1d";
export var time = "Article-module--time--yg6Rj";
export var title = "Article-module--title--81Ym+";